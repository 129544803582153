import { useNavigate } from "react-router-dom";
import { MainContainer, ContentWrapper } from "../BackGround.styles";
import InfoUpdateForm from "./InfoUpdateForm";
import { IoIosArrowBack } from "react-icons/io";

export default function InfoUpdatePage() {
  const navigate = useNavigate()
  return (
    <MainContainer>
      <ContentWrapper>
        <div className="flex w-full flex-col mt-8">
          <IoIosArrowBack className="text-3xl" onClick={() => navigate(-1)}/>
          <h2 className="font-bold text-center text-2xl">
            우리의 추억 자물쇠
          </h2>
          <p className="text-center mt-4">Lock Our Memory</p>
        </div>
        <InfoUpdateForm />
      </ContentWrapper>
    </MainContainer>
  );
}
