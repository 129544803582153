import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const ModalTitle = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px;
  color: black;
`;

export const ModalButton = styled.button`
  background-color: ${(props) => (props.secondary ? "#00397C" : "#cccccc")};
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  margin: 0 10px;

  &:hover {
    background-color: ${(props) => (props.secondary ? "#0056b3" : "#999999")};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;
