import { useNavigate } from "react-router-dom";
import AttractionButton from "../AttractionPage/AttractionButton";
import { ContentWrapper, MainContainer } from "../BackGround.styles";
import { IoIosArrowBack } from "react-icons/io";

export default function LockQuestionPage() {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <ContentWrapper className="flex flex-col items-center">
        <div className="relative w-full max-w-[400px] flex items-center mt-12 mb-4">
          <IoIosArrowBack
            className="absolute left-0 text-3xl cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h1 className="w-full text-center font-bold text-2xl">자물쇠 질문 작성</h1>
        </div>
        <AttractionButton
          className="mb-4"
          onClick={() => navigate("/create-result")}
        >
          자물쇠 완성하기
        </AttractionButton>
      </ContentWrapper>
    </MainContainer>
  );
}
