import { MainContainer } from "../BackGround.styles";
import MyPageButton from "./MyPageButton";
import MyPageCard from "./MyPageCard";
import { useNavigate } from "react-router-dom";
import { GrUserSettings } from "react-icons/gr";
import { IoIosArrowBack } from "react-icons/io";

const MyPage = () => {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <button
        onClick={() => navigate("/info-update")}
        className="absolute top-4 right-4 bg-gray-200 px-4 py-2 rounded text-sm font-semibold shadow-md"
        style={{ zIndex: 10 }}
      >
        <GrUserSettings className="w-6 h-6" />
      </button>

      <div className="flex flex-col h-full pt-20 py-10 w-full">
        <div className="relative w-full max-w-[400px] flex items-center mb-2">
          <IoIosArrowBack
            className="absolute left-5 text-3xl cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h1 className="w-full text-center font-bold text-2xl">나의 자물쇠 보기</h1>
        </div>
        <h3 className="text-center">My Memory</h3>
        <div className="grid grid-cols-2 gap-6 my-16 mx-8 h-[100vh]">
          <MyPageCard>01</MyPageCard>
          <MyPageCard>02</MyPageCard>
          <MyPageCard>03</MyPageCard>
          <MyPageCard>04</MyPageCard>
          <MyPageCard>05</MyPageCard>
          <MyPageCard>06</MyPageCard>
        </div>
        <MyPageButton>
          <p>선택한 명소 둘러보기</p>
        </MyPageButton>
      </div>
    </MainContainer>
  );
};

export default MyPage;
