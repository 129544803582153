const MyPageButton = ({ className, children, onClick }) => {
  return (
    <button
      className="bg-white text-black rounded-2xl py-4 font-bold my-2 mx-5"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default MyPageButton;
