import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100vh;
  min-height: 667px;
  background: linear-gradient(
    180deg,
    rgba(22, 5, 57, 0.9) 0%,
    rgba(59, 13, 71, 0.47) 27%,
    rgba(0, 38, 83, 0.6) 65%,
    rgba(23, 67, 110, 0.82) 100%
  );
  position: relative;
  overflow: auto;
`;

export const MainContainerDark = styled.div`
  height: 100vh;
  min-height: 667px;
  background: linear-gradient(
    180deg,
    rgba(22, 5, 57, 0.9) 0%,
    rgba(59, 13, 71, 0.74) 27%,
    rgba(0, 38, 83, 1) 65%,
    rgba(23, 67, 110, 0.82) 100%
  );
  position: relative;
  overflow: auto;
`;

export const InputContainer = styled.div`
  margin: 12px 28px; /* mx-5와 동일한 좌우 마진 */
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  font-size: 16px;
  outline: none;
  margin-top: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  color: black;

  ::placeholder {
    color: #464646;
    font-weight: 800; /* 쉼표 제거 */
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  min-height: 667px;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  position: relative;
  z-index: 2;
  padding: 20px;
  color: white;
`;

MainContainer.displayName = "MainContainer";
MainContainerDark.displayName = "MainContainerDark";
InputContainer.displayName = "InputContainer";
StyledInput.displayName = "StyledInput";
