const AttractionButton = ({ className, children, onClick }) => {
  return (
    <button
      className={`bg-white text-black 
      rounded-xl h-[50px] w-[300px] my-4
      font-normal text-base ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default AttractionButton;
