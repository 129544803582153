import { ContentWrapper, MainContainer } from "../BackGround.styles";
import SignInForm from "./SignInForm";

const SignInPage = () => {
  return (
    <MainContainer>
      <ContentWrapper>
        <div>
          <h1 className="font-bold text-center mt-20 text-2xl">
            우리의 추억 자물쇠
          </h1>
          <p className="text-center mt-4">Lock Our Memory</p>
        </div>

        <SignInForm />
      </ContentWrapper>
    </MainContainer>
  );
};

export default SignInPage;
