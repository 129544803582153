import { ContentWrapper, MainContainer } from "../BackGround.styles";
import SignUpForm from "./SignUpForm";

const SignUpPage = () => {
  return (
    <MainContainer>
      <ContentWrapper>
        <div>
          <h1 className="font-bold text-center mt-14 text-2xl">회원가입을 위해</h1>
          <h1 className="font-bold text-center text-2xl mb-2">정보를 입력해주세요.</h1>
          <p className="text-center mt-4">Lock Our Memory</p>
        </div>
        <SignUpForm />
      </ContentWrapper>
    </MainContainer>
  );
};

export default SignUpPage;
