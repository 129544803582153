import React from "react";
import {
  ModalOverlay,
  ModalContent,
  ModalTitle,
  ModalButton,
  ButtonContainer
} from "./ConfirmModal.style";

export default function ConfirmModal({ title, onConfirm, onCancel }) {
  const handleOverlayClick = (e) => {
    e.stopPropagation();
    onCancel();
  };

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalTitle>{title}</ModalTitle>
        <ButtonContainer>
          <ModalButton onClick={onConfirm}>예</ModalButton>
          <ModalButton onClick={onCancel} secondary>
            아니오
          </ModalButton>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
}
