const MainButton = ({ className, children, onClick }) => {
  return (
    <button
      className="bg-white text-black
       rounded-md px-[90px] h-[50px]
       font-normal text-base"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default MainButton;
