import API from "../API"

export const signUp = async (data) => {
    return await API().post("/signup", data)
    .then((response) => response)
    .catch((error) => error)
}

export const checkEmail = async (data) => {
    return await API().post("email", data)
    .then((response) => response)
    .catch((error) => error)
}

export const signIn = async (data) => {
    return await API().post("/login", data)
    .then((response) => response)
    .catch((error) => error)
}

export const updateUserInfo = async (data) => {
    return await API().patch("/user", data)
    .then((response) => response)
    .catch((error) => error)
}

export const cancelAccount = async (data) => { 
    return await API().delete("/user", data)
    .then((response) => response)
    .catch((error) => error)
}