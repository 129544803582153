import { ContentWrapper, MainContainer } from "../BackGround.styles";
import AtrractionCard from "../AttractionPage/AtrractionCard";
import AttractionButton from "../AttractionPage/AttractionButton";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

export default function SelectDesignPage() {
  const navigate = useNavigate();

  return (
    <MainContainer>
      <ContentWrapper className="flex flex-col items-center">
        <div className="relative w-full max-w-[400px] flex items-center mt-12 mb-4">
          <IoIosArrowBack
            className="absolute left-0 text-3xl cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h1 className="w-full text-center font-bold text-2xl">자물쇠 디자인 선택</h1>
        </div>
        <div className="w-full flex justify-center items-center mb-2">
          <div className="rounded-xl aspect-square bg-slate-200 flex justify-center items-center
            max-w-[300px] sm:max-w-[300px] md:max-w-[400px] lg:max-w-[500px] xl:max-w-[600px] min-w-[200px]">
            <span className="text-center text-lg">선택한 디자인</span>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-3 mb-2 mx-4 w-full max-w-[730px]">
          <AtrractionCard>01</AtrractionCard>
          <AtrractionCard>02</AtrractionCard>
          <AtrractionCard>03</AtrractionCard>
          <AtrractionCard>04</AtrractionCard>
          <AtrractionCard>05</AtrractionCard>
          <AtrractionCard>06</AtrractionCard>
        </div>
        <AttractionButton
          className="mb-4"
          onClick={() => navigate("/create-question")}
        >
          다음 단계
        </AttractionButton>
      </ContentWrapper>
    </MainContainer>
  );
}
