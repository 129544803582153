import { useNavigate } from "react-router-dom";
import AttractionButton from "../AttractionPage/AttractionButton";
import { ContentWrapper, MainContainer } from "../BackGround.styles";

export default function CreateResultPage() {
  const navigate = useNavigate();
  
  return (
    <MainContainer>
      <ContentWrapper className="flex flex-col items-center">
        <div className="flex flex-col items-center">
          <h1 className="font-bold text-2xl mt-12 mb-2 text-center">자물쇠 완성 설정 확인하기</h1>
        </div>
        
        <div className="w-full max-w-[300px]">
          <h2 className="font-bold text-xl my-2 text-center">자물쇠 미리보기</h2>
          <div className="aspect-square bg-slate-300 rounded-xl my-6"></div>
        </div>
        
        <div className="flex flex-col items-center w-full max-w-[300px] mb-8">
          <AttractionButton className="w-full" onClick={() => navigate("")}>공유하기</AttractionButton>
          <AttractionButton className="w-full" onClick={() => navigate("/home")}>메인으로 가기</AttractionButton>
        </div>
      </ContentWrapper>
    </MainContainer>
  );
}
