import { useState } from "react";
import SignInInput from "./SignInInput";
import SignInButton from "./SignInButton";
import { signIn } from "../../apis/userApi";
import axios from "axios";

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSignIn = async () => {
    // console.log(email, password);
    const response = await signIn({
      email: email,
      password: password,
    });
    console.log(response.headers.authorization)
    axios.defaults.headers.common['Authorization'] = response.headers.authorization
  };


  return (
    <div className="flex flex-col w-full h-full max-w-[500px] justify-between">
      <div className="flex flex-col py-14">
      <div className="flex flex-col">
          <p className="font-bold ml-3">이메일</p>
          <SignInInput
            placeholder="* 이메일을 입력해주세요."
            value={email}
            type="email"
            onChange={handleEmailChange}
          />
        </div>
        <div className="flex flex-col">
          <p className="font-bold ml-3">비밀번호</p>
          <SignInInput
            placeholder="* 비밀번호를 입력해주세요."
            value={password}
            type="password"
            onChange={handlePasswordChange}
          />
        </div>
      </div>
        <SignInButton onClick={() => handleSignIn()}>
          <p>로그인</p>
        </SignInButton>
    </div>
  );
};

export default SignInForm;
