import { useEffect, useState } from "react";
import SignUpInput from "./SignUpInput";

const PasswordInput = ({ value, handlePasswordChange }) => {
  const [pwValid, setPwValid] = useState(false);

  const validatePassword = (pwd) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,16}$/;
    if (!passwordRegex.test(pwd)) {
      setPwValid(true);
    } else {
      setPwValid(false);
    }
  };

  useEffect(() => {
    validatePassword(value);
  }, [value]);

  return (
    <div className="flex flex-col">
      <p className="font-bold ml-3">비밀번호</p>
      <SignUpInput
        placeholder="* 비밀번호를 입력하세요."
        value={value}
        type="password"
        onChange={handlePasswordChange}
      />
      {value && pwValid && (
        <p className="self-end text-xs text-[#FF8D8D] text-right">
        {"영대소문자, 숫자, 특수문자 각 1자 이상 포함"}
        <br />
        {"8~16자를 입력해주세요"}
      </p>
      )}
    </div>
  );
};

export default PasswordInput;
