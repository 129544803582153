import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainButton from "./MainButton";
import { ContentWrapper, MainContainer } from "../BackGround.styles";
import { useEffect } from "react";
import API from "../../API";

export default function MainPage() {
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await API().post("reissue");
        console.log(response);
        axios.defaults.headers.common['Authorization'] = response.headers.authorization;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  return (
    <MainContainer>
      <ContentWrapper>
        <div>
          <h2 className="font-bold text-center mt-20 text-2xl">
            우리의 추억 자물쇠
          </h2>
          <p className="text-center mt-4">Lock Our Memory</p>
        </div>
        <div className="flex flex-col gap-[10px] mb-10">
          <MainButton onClick={() => navigate("/create-select-place")}>
            <p>나만의 자물쇠 만들기</p>
          </MainButton>
          <MainButton onClick={() => navigate("")}>
            <p to="/look-around">명소 둘러보기</p>
          </MainButton>
          <MainButton onClick={() => navigate("/my-page")}>
            <p to="/my-page">나의 자물쇠 보기</p>
          </MainButton>
        </div>
      </ContentWrapper>
    </MainContainer>
  );
}
