import { ContentWrapper, MainContainer } from "../BackGround.styles";
import AtrractionCard from "./AtrractionCard";
import AttractionButton from "./AttractionButton";

const AttractionPage = () => {

  return (
    <MainContainer>
      <ContentWrapper>
        <div className="flex flex-col items-center">
          <h1 className="font-bold text-2xl mt-12 mb-2">명소 선택하기</h1>
          <h3>Lock Our Memory</h3>
        </div>
        <div className="grid grid-cols-2 place-items-center w-full gap-5 my-10 mx-4 max-w-[730px] h-[60vh]">
          <AtrractionCard>01</AtrractionCard>
          <AtrractionCard>02</AtrractionCard>
          <AtrractionCard>03</AtrractionCard>
          <AtrractionCard>04</AtrractionCard>
          <AtrractionCard>05</AtrractionCard>
          <AtrractionCard>06</AtrractionCard>
        </div>
        <AttractionButton>선택한 명소로 이동하기</AttractionButton>
      </ContentWrapper>
    </MainContainer>
  );
};

export default AttractionPage;
