import axios from "axios";

const DOMAIN = process.env.REACT_APP_DOMAIN;

export default function API() {
  const instance = axios.create({
    baseURL: DOMAIN,
    withCredentials: true,
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      console.log(error)
      // if (error.response.status === 401) {
      //   const res = await axios.post("reissue");
      //   // access header
      //   axios.defaults.headers.common["Authorization"] =
      //     res.headers.accessToken;
      //   console.log("refresh 요청함");
      //   return Promise.reject(error);
      // } else {
      //   // JWT 외의 에러는 그냥 반환
      //   return Promise.reject(error);
      // }
    }
  );
  return instance;
}
