import { useState } from "react";
import checkIcon from "../../assets/check.png";
import ReusableModal from "../../components/ReusableModal";
import { useNavigate } from "react-router-dom";
import { cancelAccount, updateUserInfo } from "../../apis/userApi";
import InputContainer from "../../components/InputContainer";
import PasswordInput from "../SignUpPage/PasswordInput";
import SubmitButton from "../../components/SubmitButton";
import ConfirmModal from "./ConfirmModal";

const InfoUpdateForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const navigate = useNavigate();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/home");
  };

  const handleNicknameChange = (e) => {
    setNickname(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handlePasswordCheckChange = (e) => {
    setPasswordCheck(e.target.value);
  };
  const handleUpdate = async () => {
    const response = await updateUserInfo({ nickname, password });
    if (response.status === 200) openModal();
  };

  const openConfirmModal = () => setIsConfirmModalOpen(true);
  const closeConfirmModal = () => setIsConfirmModalOpen(false);

  const handleConfirmCancelAccount = async () => {
    const response = await cancelAccount();
    if (response.status === 200) {
      navigate("/");
    }
  };
  return (
    <div className="flex flex-col w-full h-full max-w-[500px] justify-between">
      <div className="flex flex-col py-14">
        <div className="flex flex-col">
          <p className="font-bold ml-3">닉네임</p>
          <InputContainer
            placeholder="* 닉네임을 입력하세요."
            value={nickname}
            type="text"
            onChange={handleNicknameChange}
          />
        </div>

        <PasswordInput
          value={password}
          handlePasswordChange={handlePasswordChange}
        />
        <div className="flex flex-col">
          <p className="font-bold ml-3">비밀번호 확인</p>
          <InputContainer
            placeholder="* 비밀번호를 재입력하세요."
            value={passwordCheck}
            type="password"
            onChange={handlePasswordCheckChange}
          />
          <p className="self-end text-xs text-[#FF8D8D]">
            {password !== passwordCheck ? "비밀번호가 일치하지 않습니다" : ""}
          </p>
        </div>
        <div className="flex-grow"></div>
      </div>
      <div className="flex flex-col">
        <SubmitButton onClick={handleUpdate}>
          <p>수정하기</p>
        </SubmitButton>
        <button className="text-sm font-thin" onClick={openConfirmModal}>
          탈퇴하기
        </button>
      </div>
      {isModalOpen && (
        <ReusableModal
          iconSrc={checkIcon}
          title="회원정보 수정이 완료되었습니다."
          message="메인 화면으로 이동 후 이용해주세요."
          buttonText="메인 화면으로 돌아가기"
          onClose={closeModal}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          title="정말 탈퇴하시겠습니까?"
          onConfirm={handleConfirmCancelAccount}
          onCancel={closeConfirmModal}
        />
      )}
    </div>
  );
};

export default InfoUpdateForm;
