const SubmitButton = ({ className, children, onClick }) => {
    return (
      <button
        className={`bg-white/80 text-black 
            py-4 my-2 h-[50px] px-21
            rounded-xl font-bold
            ${className}`}
        onClick={onClick}
      >
        {children}
      </button>
    );
  };;
  
  export default SubmitButton;
  