import React from "react";
import {
  ModalOverlay,
  ModalContent,
  ModalIcon,
  ModalTitle,
  ModalMessage,
  ModalButton
} from './Modal.style';

export default function ReusableModal ({ iconSrc, title, message, buttonText, onClose }) {
  const handleOverlayClick = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {iconSrc && <ModalIcon src={iconSrc} alt="modal icon" />}
        <ModalTitle>{title}</ModalTitle>
        <ModalMessage>{message}</ModalMessage>
        <ModalButton onClick={onClose}>{buttonText}</ModalButton>
      </ModalContent>
    </ModalOverlay>
  );
};
