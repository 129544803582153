import { ContentWrapper, MainContainer } from "../BackGround.styles";
import AtrractionCard from "../AttractionPage/AtrractionCard";
import AttractionButton from "../AttractionPage/AttractionButton";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

export default function SelectPlacePage() {
  const navigate = useNavigate();

  return (
    <MainContainer>
      <ContentWrapper className="flex flex-col items-center">
        <div className="relative w-full mt-12 mb-4 flex items-center">
          <IoIosArrowBack
            className="absolute left-0 text-3xl cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h1 className="w-full text-center font-bold text-2xl">명소 선택하기</h1>
        </div>
        <div className="grid grid-cols-2 gap-3 mb-2 mx-4 w-full max-w-[400px]">
          <AtrractionCard>01</AtrractionCard>
          <AtrractionCard>02</AtrractionCard>
          <AtrractionCard>03</AtrractionCard>
          <AtrractionCard>04</AtrractionCard>
          <AtrractionCard>05</AtrractionCard>
          <AtrractionCard>06</AtrractionCard>
        </div>
        <AttractionButton
          className="my-10"
          onClick={() => navigate('/create-select-lock-design')}
        >
          다음 단계
        </AttractionButton>
      </ContentWrapper>
    </MainContainer>
  );
}
