import React from "react";
import { useNavigate } from "react-router-dom";
import { MainContainer } from "../BackGround.styles";
import {
  ContentWrapper,
  ButtonContainer,
  Button,
  SignUpText,
} from "./LoginMainPage.styles";
import kakao from "../../assets/kakaologo.png";
import google from "../../assets/web_neutral_rd_na@4x.png";
import naver from "../../assets/naver2.png"

export default function LoginMainPage() {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <ContentWrapper>
        <div>
          <h2 className="font-bold text-center mt-20 text-2xl">
            우리의 추억 자물쇠
          </h2>
          <p className="text-center mt-4">Lock Our Memory</p>
        </div>
        <ButtonContainer>
          <Button $bgcolor="#03C75A" onClick={() => window.location.href = "https://api.mydevlogme.site/oauth2/authorization/naver"}>
            <img
              src={naver}
              alt="네이버 로그인 버튼"
              style={{ width: "25px", marginRight: "10px", marginLeft: "0px" }}
            />
            <span style={{ padding: "0 18px", textAlign: "center", marginRight: "1px" }}>
              네이버 로그인
            </span>
          </Button>
          
          <Button $bgcolor="#FEE500">
            <img
              src={kakao}
              alt="카카오 로그인 버튼"
              style={{ width: "25px", marginRight: "10px", marginLeft: "0px" }}
            />
            <span
              style={{
                padding: "0 18px",
                textAlign: "center",
                marginRight: "1px",
              }}
            >
              카카오 로그인
            </span>
          </Button>
          <Button $bgcolor="#F2F2F2" $textcolor="#000" onClick={() => window.location.href = "https://api.mydevlogme.site/oauth2/authorization/google"}>
            <div className="w-full flex flex-row items-center justify-between">
              <img
                src={google}
                alt="구글 로그인 버튼"
                style={{
                  width: "40px",
                  marginRight: "10px",
                  marginLeft: "5px",
                }}
              />
              <span
                style={{
                  padding: "0 18px",
                  textAlign: "center",
                  marginRight: "23px",
                }}
              >
                구글 로그인
              </span>
            </div>
          </Button>
          <Button
            $bgcolor="rgba(255,255,255,0.8)"
            $textcolor="#000"
            onClick={() => navigate("/attraction")}
          >
            {/* 아이콘 없이 동일한 레이아웃 유지 */}
            <span style={{ flex: 1, textAlign: "center", padding: 6 }}>
              비회원으로 둘러보기
            </span>
          </Button>
          <div className="flex flex-row gap-3 justify-center">
            <SignUpText onClick={() => navigate("/site-sign-in")}>
              로그인
            </SignUpText>
            <SignUpText onClick={() => navigate("/site-sign-up")}>
              회원가입
            </SignUpText>
          </div>
        </ButtonContainer>
      </ContentWrapper>
    </MainContainer>
  );
}
