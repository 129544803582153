const SignUpInput = ({ onClick, placeholder, value, onChange, type }) => {
  return (
    <input
      className="bg-white/0.9 text-black
      rounded-xl py-3 
      font-bold my-2 opacity-75 pl-5"
      onClick={onClick}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    ></input>
  );
};

export default SignUpInput;
