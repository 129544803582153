import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const ModalIcon = styled.img`
  width: 40px;
  height: 40px;
  margin: 10px auto 10px auto;
  display: block;
`;

export const ModalTitle = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px;
  color: black;
`;

export const ModalMessage = styled.p`
  font-size: 0.9rem;
  color: black;
  margin-bottom: 20px;
  font-weight: 800;
`;

export const ModalButton = styled.button`
  background-color: #00397C;
  color: white;
  padding: 10px 40px;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;
