import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage";
import LoginMainPage from "./pages/LoginPage/LoginMainPage";
import CreateNickNamePage from "./pages/LoginPage/CreateNickNamePage";
import MyPage from "./pages/MyPage/MyPage";
import SignUpPage from "./pages/SignUpPage/SignUpPage";
import InfoUpdatePage from "./pages/MyPage/InfoUpdatePage";
import AttractionPage from "./pages/AttractionPage/AttractionPage";
import SignInPage from "./pages/SignInPage/SignInPage";
import SelectPlacePage from "./pages/CreateLockPage/SelectPlacePage";
import LockDesignPage from "./pages/CreateLockPage/LockDesignPage";
import LockQuestionPage from "./pages/CreateLockPage/LockQuestionPage";
import CreateResultPage from "./pages/CreateLockPage/CreateResultPage";

export default function Router() {
  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginMainPage />}/>
        <Route path="/sign-up" element={<CreateNickNamePage />}/>
        <Route path="/home" element={<MainPage />}/>
        <Route path="/my-page" element={<MyPage />}/>
        <Route path="/info-update" element={<InfoUpdatePage />}/>
        <Route path="/site-sign-up" element={<SignUpPage />}/>
        <Route path="/site-sign-in" element={<SignInPage />}/>
        <Route path="/attraction" element={<AttractionPage />}/>
        <Route path="/create-select-place" element={<SelectPlacePage />} />
        <Route path="/create-select-lock-design" element={<LockDesignPage />}/>
        <Route path="/create-question" element={<LockQuestionPage/>}/>
        <Route path="/create-result" element={<CreateResultPage />} />
      </Routes>
    </BrowserRouter>
  )
};